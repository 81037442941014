import { useEffect, useState, useContext } from 'react';
import dashboardContext from "../context/dashboard";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import axios from 'axios';
import { formatDate } from '../utils/dashboard';

const Word = () => {
    const { allDictionary, toggler, term, setTerm  } = useContext(dashboardContext)
    const [wordDictionary, setWordDictionary] = useState()
    const [definition, setDefinition] = useState()

    useEffect(() => {
        axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${term}`)
        .then(response => {
            const data = response.data[0];
            setDefinition(data);
        }).catch(err => console.log(err))
        
    },[])
    useEffect(() => {
        let dictionary = {
            current: {},
            other: [],
            mostUsedNote: {},
            firstUsedNote: {},
            latestUsedNote: {}
        };
        const key = toggler ? 'phrases' : 'words';
        allDictionary.current[key].forEach(item => {
            if(item.term.toLowerCase() === term.toLowerCase()){
                dictionary.current = {...item}
            }
        })
        allDictionary.other.forEach(dict => {
            dict[key].forEach(item => {
                if(item.term.toLowerCase() === term.toLowerCase()) {
                    dictionary.other.push(item)
                }
            })
        })
        dictionary.mostUsedNote = dictionary.current.notes[0];
        dictionary.firstUsedNote = dictionary.current.notes[0];
        dictionary.latestUsedNote = dictionary.current.notes[0];
        dictionary.current.notes.forEach(note => {
            if (note.count > dictionary.mostUsedNote.count) {
              dictionary.mostUsedNote = note;
            }
          
            if (new Date(note.timestamp) < new Date(dictionary.firstUsedNote.timestamp)) {
              dictionary.firstUsedNote = note;
            }
          
            if (new Date(note.timestamp) > new Date(dictionary.latestUsedNote.timestamp)) {
              dictionary.latestUsedNote = note;
            }
          });
        setWordDictionary(dictionary)
    },[allDictionary])
    console.log(wordDictionary)
    return (
        <div className="content-main">
            <div className="word-head">
                <IoMdArrowRoundBack onClick={() => setTerm('')} size={25} cursor="pointer" color="#444444"/>
                <h2>{term}</h2>
            </div>
            <div className="divider"></div>
            <div className="word-main">
                {definition && <div className='highlight'>
                    <h3>Definition of {term}</h3>
                    <div className="word-meaning">
                        <RiDoubleQuotesL color="#8884d8" size={18}/>
                        <i>{definition?.meanings[0].definitions[0].definition}</i>
                        <RiDoubleQuotesR color="#8884d8" size={18}/>
                    </div>
                    <ul>
                        {definition?.meanings[0].synonyms.map((synonym, index) => (
                            <li key={index}>{synonym}</li>
                        ))}
                    </ul>
                </div>}
            </div>

            <div>

      {/* Student Usage Section */}
      <section className="usage-section">
        <h3 className="section-title">Your Usage</h3>
        <div className="highlight-cards">
            <div className="usage-card">
              <p>Most used in</p>
              <h4>{wordDictionary?.mostUsedNote.title}</h4>
              <div className='usage-info-container'>
                <p><strong>Used:</strong> {wordDictionary?.mostUsedNote.count} times</p>
                <p><strong>On:</strong> {formatDate(wordDictionary?.mostUsedNote.timestamp)}</p>
                </div>
            </div>
            <div className="usage-card">
              <p>First used in</p>
              <h4>{wordDictionary?.firstUsedNote.title}</h4>
              <div className='usage-info-container'>
                <p><strong>Used:</strong> {wordDictionary?.firstUsedNote.count} times</p>
                <p><strong>On:</strong> {formatDate(wordDictionary?.firstUsedNote.timestamp)}</p>
                </div>
            </div>
            <div className="usage-card">
              <p>Recently used in</p>
              <h4>{wordDictionary?.latestUsedNote.title}</h4>
              <div className='usage-info-container'>
                <p><strong>Used:</strong> {wordDictionary?.latestUsedNote.count} times</p>
                <p><strong>On:</strong> {formatDate(wordDictionary?.latestUsedNote.timestamp)}</p>
                </div>
            </div>
        </div>
        
        <div className="usage-list">
        {wordDictionary?.current.notes.map((usage, index) => {
            if(usage.title !== wordDictionary?.mostUsedNote.title && usage.title !== wordDictionary?.firstUsedNote.title && usage.title !== wordDictionary?.latestUsedNote.title)
            return <div key={index} className="usage-item">
                    <p><strong>{usage.title}</strong></p>
                    <p>Used <strong>{usage.count}</strong> times | On <strong>{formatDate(usage.timestamp)}</strong></p>
                </div>
        })}
        </div>
      </section>

      <section className="usage-section">
        <h3 className="section-title">Community Usage</h3>

        <div className="usage-table">
          <table>
            <thead>
              <tr>
                <th>Note</th>
                <th>Count</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {wordDictionary?.other.map(usage => (
                usage.notes.map((note,index) => (
                     <tr key={index}>
                  <td><a href={`${process.env.REACT_APP_KF6_URL}/contribution/${note.id}/${note.viewId}`} rel="noreferrer" target='_blank'>{note.title}</a></td>
                  <td>{note.count}</td>
                  <td>{formatDate(note.timestamp)}</td>
                </tr>
              ))))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
        </div>
    );
}

export default Word;