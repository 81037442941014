import React from "react";

const defaultValue = {
    selectedUser: "",
    community: {},
    studentsResult: {},
    clusteredData: {},
    selectedWeek: "",
    active: "",
    role: "",
    userId: "",
    communityId: "",
    setClusteredData: () => { },
    setSelectedWeek: () => { },
    toggler: false,
    setToggler: () => { },
    allDictionary: {},
    term: '',
    setTerm: () => {}
};

const dashboardContext = React.createContext(defaultValue);
export default dashboardContext;