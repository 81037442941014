import { useLocation } from "react-router-dom";
import Dictionary from "../Queries/Dictionary";
import Main from "../components/Main";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { formatDate } from "../utils/dashboard";
import dashboardContext from "../context/dashboard";
import History from "../components/History";
import '../utils/style.css';
import { TbHomeStats } from "react-icons/tb";
import { SiDictionarydotcom } from "react-icons/si";
import { GiProgression } from "react-icons/gi";
import { BsChatSquareText } from "react-icons/bs";
import Animation from "../components/Animation";
import Word from "../components/Word";

const Dashboard = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId')
    const role = queryParams.get('role')
    const communityId = queryParams.get('communityid')
    const { data: communityResult, loading: cLoading } = useQuery(Dictionary.getCommunity, { variables: { id: communityId, userId: userId } })
    const community = communityResult?.community
    const studentsResult = role === 'writer' ? communityResult?.community.author : communityResult?.community.writers
    const [selectedUser, setSelectedUser] = useState()
    const [toggler, setToggler] = useState(false)
    const [active, setActive] = useState('Dashboard')
    const [clusteredData, setClusteredData] = useState({})
    const [selectedWeek, setSelectedWeek] = useState('')
    const [allDictionary, setAllDictionary] = useState();
    const [animationsComplete, setAnimationsComplete] = useState(false);
    const [loadNewUser, setLoadNewUser] = useState(false)
    const [term, setTerm] = useState('')
    const { data, loading } = useQuery(Dictionary.getDictionary, {
        variables: {
            authorId:
                role === 'writer'
                    ? studentsResult?.id
                    : selectedUser
                    ? selectedUser
                    : studentsResult?.[0]?.id,
            communityId: communityId,
        },
        skip: !studentsResult,
        onCompleted: (data) => setAllDictionary(data.dictionary)
    });
    const getDictionary = (event) => {
        setLoadNewUser(true)
        const current = {...allDictionary.current}
        const newCurrent = allDictionary.other.find(dict => dict.authorId === event.target.value) 
        if (newCurrent) {
            setAllDictionary({
                current: newCurrent,
                other: [
                    ...allDictionary.other.filter(dict => dict.authorId !== event.target.value), // Remove `newCurrent` from `other`
                    current
                ]
            });
            setLoadNewUser(false)
        }
    }
    useEffect(()=>{
        setTerm('')
    },[active])
    // const [l, setL] = useState(true)
    // useEffect(() => {
    //     setTimeout(() => {
    //         setL(false) 
    //     }, 10000);
    // }, [])
    if (!animationsComplete) {
        return (
          <Animation
            isLoading={loading}
            onAnimationsComplete={() => setAnimationsComplete(true)}
          />
        );
    }
    return (
        <dashboardContext.Provider 
            className = "root"
            value = {{ 
                selectedUser, role, userId, communityId, selectedWeek, 
                community, studentsResult, clusteredData, active, 
                setClusteredData, setSelectedWeek, toggler,
                setToggler, allDictionary, term, setTerm
            }}
        >
            <div className="header">
                <h1>{community ? community.title : 'Loading Community...'}</h1>
                {role === 'writer'
                    ? <h4>{studentsResult?.firstName} {studentsResult?.lastName}</h4>
                    
                    : <select id='student-select' onChange={getDictionary}>
                        {studentsResult?.map((student, index) => <option key={index} value={student.id}>{student.firstName} {student.lastName}</option>)}
                    </select>
                    }
            </div>
            <div className="main">
                <div className='sidebar'>
                    <div className='main-sidebar' style={{ backgroundColor: active === 'Dashboard' ? '#9B80BC' : '', color: active === 'Dashboard' ? '#ffffff' : '#333333' }} onClick={() => setActive('Dashboard')}>
                        <TbHomeStats />
                        <span className="sidebar-text">Dashboard</span>
                    </div>
                    <div className='main-sidebar' style={{ backgroundColor: active === 'Dictionary' ? '#9B80BC' : '', color: active === 'Dictionary' ? '#ffffff' : '#333333' }} onClick={() => setActive('Dictionary')}>
                        <SiDictionarydotcom />
                        <span className="sidebar-text">Word Dictionary</span>
                    </div>
                    <div className='main-sidebar' style={{ backgroundColor: active === 'Progress' ? '#9B80BC' : '', color: active === 'Progress' ? '#ffffff' : '#333333' }} onClick={() => setActive('Progress')}>
                        <GiProgression />
                        <span className="sidebar-text">Weekly Progress</span>
                    </div>
                    {active === 'Progress' && <div style={{ marginLeft: 20, marginTop: 10 }}>
                        {
                            clusteredData.current.map((cluster, index) => {
                                return (
                                    <div key={index} style={{ backgroundColor: cluster.weekStartDate === selectedWeek ? '#9B80BC' : '', color: cluster.weekStartDate === selectedWeek ? '#ffffff' : '#333333' }} onClick={() => setSelectedWeek(cluster.weekStartDate)} className='nested-sidebar'>
                                        {formatDate(cluster.weekStartDate) + ' - ' + formatDate(cluster.weekEndDate)}
                                    </div>
                                )
                            }
                            )
                        }
                    </div>}
                    <div className='main-sidebar' style={{ backgroundColor: active === 'History' ? '#9B80BC' : '', color: active === 'History' ? '#ffffff' : '#333333', margin: '15px 0' }} onClick={() => setActive('History')}>
                        <BsChatSquareText />
                        <span className="sidebar-text">Chat History</span>
                    </div>
                </div>
                <div className="content">
                    { active === 'History' ? <History/> : term ? <Word/> : <Main loadNewUser={loadNewUser}/>}
                </div>
            </div>
        </dashboardContext.Provider>
    );
}

export default Dashboard;